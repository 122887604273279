import Cookies from 'js-cookie';
import STATUS from '../store/globalStatuses';
import HTTP_STATUS from '../store/httpStatuses';
import HighestEducationFieldTypes from '../components/grouped/background/highest-education/highestEducationField.data';

export const getPersonId = reduxState => {
  // Set defaults to avoid drilling into undefined properties
  const authPersonId = (((reduxState.oidc || {}).user || {}).profile || {})['custom:STUDENT_PERSON_ID'];
  const { personId } = (reduxState.person || {}).person || {};
  // First choice is personId from auth otherwise from state then sessionStorage (for old quick-app)
  return authPersonId || personId || sessionStorage.getItem('personId') || reduxState.person?.person?.id;
};

export const getAssociation = associations => {
  const apiAssociation = associations.find(association => association.categoryName === 'Association');
  return apiAssociation
    ? {
      id: (apiAssociation?.externalSystemIds?.PROFILE_ASSOCIATION_ID || [''])[0],
      categoryName: apiAssociation.categoryName,
      dunsNumber: apiAssociation.dunsNumber,
      value: apiAssociation.firmName,
      label: apiAssociation.firmName,
    }
    : {};
};

export const getEmployerTribe = associations => {
  const associationEmployer = (Object.keys(associations).map(key => associations[key])).find(association => association.categoryName === 'Association');
  const employer = { ...associationEmployer };
  return employer.firmName;
};

export const getRegistrationSource = genericinfo => {
  const regSourceObj = genericinfo.filter(entry => entry.attribute === 'regSource');
  if (regSourceObj && regSourceObj.length > 0) {
    return regSourceObj[0].value;
  }
  return '';
};

export const personalInformationCompleted = state => ((((state?.saga?.workflow)[0])?.tasks)?.find(task => task?.referenceTaskName === 'personalInformation'))?.taskStatus === 'COMPLETED';
export const getFullCountryName = (countryCode, countries) => (countries
  .find(country => country.code === countryCode));

export const shouldFetch = ({ getStatus }) => getStatus === STATUS.UNFETCHED
|| getStatus === STATUS.MISSING;
export const areDependenciesResolved = (...args) => args.every(
  ({ getStatus }) => getStatus === STATUS.FETCHED
  || getStatus === STATUS.MISSING
  || getStatus === STATUS.ERROR,
);

export const buildDependenciesErroredArray = (...args) => args
  .filter(
    status => status.getStatus === STATUS.ERROR
      && status.errorData?.status !== 404
      && status.errorData,
  )
  .map(status => ({
    errorData: status.errorData,
  }));

export const areRequiredSaveCallsResolved = (...args) => args.every(
  status => status.modifyStatus === STATUS.UPDATED || status.modifyStatus === STATUS.ERROR,
);

export const buildSaveCallsErroredArray = (...args) => args
  .filter(
    status => status.modifyStatus === STATUS.ERROR
      && status.errorData,
  )
  .map(status => ({
    errorData: status.errorData,
  }));

export const createRequestAction = (error, missingType, errorType) => (
  error.status === HTTP_STATUS.NOT_FOUND
    ? { type: missingType, receivedAt: Date.now() }
    : { type: errorType, error, receivedAt: Date.now() });
export function getIndianaConsent(phoneConsents) {
  if (Array.isArray(phoneConsents)) {
    const result = phoneConsents.filter(consent => consent.consentType === 'TCPA-IN')[0];
    return result?.isAutoDialConsent === 'true';
  }
  return false;
}

export const optionalSaveDependencyBuilder = optionalStatusList => (
  getFormValue => (optionalStatusList.every(statusItem => {
    const conditionValidationCb = field => (
      field.fieldCondition(getFormValue(field.fieldName))
    );

    let areConditionsSatisfied = false;
    switch (statusItem.conditionJoiner) {
      case 'OR':
        areConditionsSatisfied = statusItem.dependentFormFields.some(conditionValidationCb);
        break;
      case 'AND':
      default:
        areConditionsSatisfied = statusItem.dependentFormFields.every(conditionValidationCb);
    }

    if (areConditionsSatisfied) {
      return statusItem.modifyStatus === STATUS.UPDATED;
    }
    return true;
  }))
);

export const isPersonaInformationSectionValid = validityBySection => [
  validityBySection.name,
  validityBySection.email,
  validityBySection.phone,
  validityBySection.country,
  validityBySection.address,
].every(val => val === true);

export const isBackgroundSectionValid = (
  validityBySection,
  citizenship,
  hasMilitary,
  currentServiceType,
  serviceBranch,
  militaryInstallation,
  serviceStartDate,
  serviceEndDate,
  hasVeteranService,
  installationCountry,
  hasDod,
  hasDodVeteranServiceOrganization,
) => {
  const backgroundSectionValidationList = [];
  const { citizenRadio, citizenshipCountry } = validityBySection;
  const visaTypeIsValid = validityBySection.visaType;
  const last4SSNIsValid = validityBySection.last4Ssn;
  const dobIsValid = validityBySection.dateOfBirth;
  const { highestEducationType } = validityBySection;
  const { association } = validityBySection;
  const {
    isArmedForces,
    armedForcesServiceType,
    armedForcesServiceBranch,
    isArmedForcesBase,
    armedForcesServiceStartDate,
    armedForcesServiceSeparationDate,
    armedForcesInstallation,
    armedForcesInstallationCountry,
    isArmedForcesOrganization,
    armedForcesOrganization,
  } = validityBySection.military.armedForces;
  const {
    isDepartmentOfDefenseIsValid,
    departmentOfDefenseInstallation,
    isDepartmentOfDefenseOrganization,
    departmentOfDefenseOrganization,
  } = validityBySection.military.departmentOfDefense;

  backgroundSectionValidationList.push(dobIsValid);
  backgroundSectionValidationList.push(last4SSNIsValid);
  backgroundSectionValidationList.push(highestEducationType);
  backgroundSectionValidationList.push(association);
  backgroundSectionValidationList.push(isArmedForces);
  backgroundSectionValidationList.push(isDepartmentOfDefenseIsValid);

  if (citizenship === 'No') {
    backgroundSectionValidationList.push(citizenRadio);
    backgroundSectionValidationList.push(citizenshipCountry);
    backgroundSectionValidationList.push(visaTypeIsValid);
  }
  if (hasMilitary === 'Yes') {
    backgroundSectionValidationList.push(isArmedForcesOrganization);
    backgroundSectionValidationList.push(isArmedForcesBase);
    if (currentServiceType)backgroundSectionValidationList.push(armedForcesServiceType);
    if (serviceBranch)backgroundSectionValidationList.push(armedForcesServiceBranch);
    if (militaryInstallation)backgroundSectionValidationList.push(armedForcesInstallation);
    if (serviceStartDate)backgroundSectionValidationList.push(armedForcesServiceStartDate);
    if (serviceEndDate)backgroundSectionValidationList.push(armedForcesServiceSeparationDate);
    if (installationCountry)backgroundSectionValidationList.push(armedForcesInstallationCountry);
    if (hasVeteranService === 'Yes')backgroundSectionValidationList.push(armedForcesOrganization);
  }
  if (hasDod === 'Yes') {
    backgroundSectionValidationList.push(departmentOfDefenseInstallation);
    backgroundSectionValidationList.push(isDepartmentOfDefenseOrganization);
    if (hasDodVeteranServiceOrganization === 'Yes')backgroundSectionValidationList.push(departmentOfDefenseOrganization);
  }
  return backgroundSectionValidationList.every(val => val === true);
};

export const getIsUserDataRestricted = reduxState => (
  (reduxState.personName?.name?.restricted || '').toLowerCase() === 'true'
);

export const resetValueIfNeeded = highestEducationLevel => {
  const highestEducationTypes = HighestEducationFieldTypes.map(type => type.value);
  return highestEducationTypes.includes(highestEducationLevel) ? highestEducationLevel : null;
};

export const checkSkipMaintenancePage = () => {
  const skipMaintenanceUrl = window.location.href.toLowerCase().includes('skipmaintenance=true');
  const skipMaintenanceSession = sessionStorage.getItem('skipMaintenance') === 'true';
  const skipMaintenance = skipMaintenanceUrl || skipMaintenanceSession;
  if (skipMaintenance) {
    sessionStorage.setItem('skipMaintenance', 'true');
  }
  return skipMaintenance;
};

export const clearSkipMaintenanceFlag = () => {
  if (window.location.href.toLowerCase().includes('skipmaintenance=false')) {
    sessionStorage.removeItem('skipMaintenance');
    return true;
  }
  return false;
};

export const setPersonIdCookie = personId => {
  Cookies.set('NDR.personId', personId, {
    secure: true,
    domain: 'phoenix.edu',
    sameSite: 'strict',
  });
};

export const getTealiumVisitorId = () => {
  const cookie = Cookies.get('utag_main');
  if (cookie) {
    const parts = cookie.split('$');
    const id = parts.find(item => item.includes('v_id'));
    return id?.split(':')[1] || '';
  }
  return '';
};

export const getTealiumSessionId = () => {
  const cookie = Cookies.get('utag_main');
  if (cookie) {
    const parts = cookie.split('$');
    const id = parts.find(item => item.includes('ses_id'));
    return id?.split(':')[1]?.split(';')[0] || '';
  }
  return '';
};

export const getGoogleId = () => Cookies.get('_ga') || '';
export const getContentSquareId = () => Cookies.get('_cs_id')?.split('.')[0] || '';
export const getSegmentAnonymousId = () => Cookies.get('ajs_anonymous_id') || '';

export const getPrimary = data => {
  let primary = data[0];
  const primaryItems = data.filter(entry => entry.primary === 'true');
  if (primaryItems && primaryItems.length > 0) {
    [primary] = primaryItems;
  }
  return primary;
};

/*
 * Returns a reduced array of the first item matching the condiition function provided
 * and falls back to original array when no match is found
 * eg: getReducedArrayByCondition([3,4,2], element => element === 4 ) will return [4]
 * eg: getReducedArrayByCondition([3,4,2], element => element === 1 ) will return [3,4,2]
 */
export const getReducedArrayByCondition = (originalArray, conditionFn) => {
  const reducedArray = originalArray.reduce((currentReducedData, currentItem) => {
    if (conditionFn(currentItem) && currentReducedData === originalArray) {
      return [currentItem];
    }
    return currentReducedData;
  }, originalArray);
  return reducedArray;
};

export const userId = reduxState => {
  const userName = reduxState.personMeta?.meta?.username
    || reduxState.person?.person?.username
    || '';
  if (userName) {
    if (userName.includes('@')) {
      return userName.split('@')[0];
    }
    return userName;
  }
  return '';
};

export const formatPhoneNumber = (phoneNumber, phoneCode) => {
  if (phoneNumber.startsWith(phoneCode)) {
    return `+${phoneNumber}`;
  }
  return (phoneNumber.includes('+') ? phoneNumber : `+${phoneCode}${phoneNumber}`);
};

// TODO - To be used when International support/TCPA checkbox are added

// export const stripPhoneNumber = (phoneNumber, countryCode) => {
//   let phoneNumUpd = phoneNumber;
//   if (phoneNumber.includes('+') && countryCode) {
//     phoneNumUpd = phoneNumber.substring(countryCode.length);
//   }
//   return phoneNumUpd;
// };

// const findPhoneWithFilter = (phoneList, isPrimary, isConsent, phoneType) => {
//   let filteredPhoneList = [];
//   if (isPrimary && isConsent) {
//     filteredPhoneList = phoneList.filter(phone => phone.primary === isPrimary
//       && phone.isAutoDialConsent === isConsent && phone.type === phoneType);
//   }
//   if (isPrimary && !isConsent) {
//     filteredPhoneList = phoneList.filter(phone => phone.primary === isPrimary
//       && phone.type === phoneType);
//   }
//   if (!isPrimary && isConsent) {
//     filteredPhoneList = phoneList.filter(phone => phone.isAutoDialConsent === isConsent
//       && phone.type === phoneType);
//   }
//   if (!isPrimary && !isConsent) {
//     filteredPhoneList = phoneList.filter(phone => phone.type === phoneType);
//   }
//   if (filteredPhoneList && filteredPhoneList.length > 0) {
//     return filteredPhoneList[0];
//   }
//   return filteredPhoneList;
// };

// const findFilteredPhoneWithAllPhoneTypes = (phoneList, isPrimary, isConsent) => {
//   const phoneTypes = ['Mobile', 'Home', 'Work'];
//   let findPhone = {};
//   for (let i = 0; i < phoneTypes.length; i++) {
//     findPhone = findPhoneWithFilter(phoneList, isPrimary, isConsent, phoneTypes[i]);
//     if (findPhone && findPhone.id) {
//       break;
//     }
//   }
//   return findPhone;
// };

// export const getPrimaryPhone = reduxState => {
//   const phoneList = reduxState.contact?.phone;
//   const consentList = reduxState?.contact?.phoneConsent;
//   const phoneConsentList = [];
//   if (phoneList && Array.isArray(phoneList) && phoneList[0].id) {
//     for (let i = 0; i < phoneList.length; i++) {
//       const findConsent = consentList && Array.isArray(consentList)
//         ? consentList.find(itmInner => itmInner.phoneNumber === phoneList[i].phoneNumber) : {};
//       const { id, isAutoDialConsent } = findConsent || {};
//       if (id) {
//         phoneConsentList.push({
//           ...phoneList[i],
//           isAutoDialConsent,
//         });
//       } else {
//         phoneConsentList.push({
//           ...phoneList[i],
//           isAutoDialConsent: 'false',
//         });
//       }
//     }
//     const primaryConsentPhones =
// findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'true', 'true');
//     if (primaryConsentPhones && primaryConsentPhones.id) {
//       return primaryConsentPhones;
//     }
//     const primaryNoConsentPhones =
// findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'true', 'false');
//     if (primaryNoConsentPhones && primaryNoConsentPhones.id) {
//       return primaryNoConsentPhones;
//     }
//     const noPrimaryConsentPhones =
// findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'false', 'true');
//     if (noPrimaryConsentPhones && noPrimaryConsentPhones.id) {
//       return noPrimaryConsentPhones;
//     }
//     const noPrimaryNoConsentPhones =
// findFilteredPhoneWithAllPhoneTypes(phoneConsentList, 'false', 'false');
//     if (noPrimaryNoConsentPhones && noPrimaryNoConsentPhones.id) {
//       return noPrimaryNoConsentPhones;
//     }
//   }
//   if (phoneList && Array.isArray(phoneList)) {
//     return phoneList[0];
//   }
//   return { phoneNumber: '' };
// };
